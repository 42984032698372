import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import axiosInstance from "../components/OrganisationSearch/services/api";
import { AsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";
import { fetchPostalCode, getCoordinatesFromPostalCode } from "../components/OrganisationSearch/utils/helpers";
import Loader from "../components/GlobalSearch/components/common/loader";
import ResetButton from "../components/OrganisationSearch/components/ResetButton";
import { customStyles } from "../components/OrganisationSearch/components/PostalCode/styles";

const IMAGE = {
    "respite": "ic_repit.svg",
    "informations": "ic_informations.svg",
    "trainings": "ic_activite_formation.svg",
    "home_assists": "ic_aide_domicile.svg",
    "administratives": "ic_juridique.svg",
    "transportations": "ic_transport.svg",
    "accommodations": "ic_hebergement.svg",
    "support_groups": "ic_entraide.svg",
};

const lang = document.documentElement.lang || "en";


export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

// eslint-disable-next-line react/prop-types
const PostalCode = ({ setPostalCode, setShowPostalCodeError, showPostalCodeError }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const currentLanguage = window.languageCode;
    const loadOptions = async (inputValue = "", prevOptions) => {
        try {
            const response = await axiosInstance.get(
                "/api/postal-code/?q=" + inputValue
            );
            const data = response.data;

            const newOptions = data.results.map((item) => ({
                ...item,
                value: item.id,
                label: item.city + "(" + item.code + `)`,
            }));
            return {
                options: newOptions,
            };
        } catch (error) {
            console.log(prevOptions);
            console.error("An error occurred while fetching data:", error);
            return [];
        }
    };

    const handleSelectedValue = async (selected) => {
        const coordinates = await getCoordinatesFromPostalCode(selected.code);
        if (coordinates) {
            const newSelected = {
                ...selected,
                coordinates: coordinates,
            };
            setSelectedOption(newSelected);
            setPostalCode(newSelected);
            setShowPostalCodeError(false);
            setInputValue(selected);
        } else {
            alert(t("Can not select this postal code at the moment, please another postalcode nearest to you."));
        }
    };

    const handleInputChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            setInputValue(newValue);
        }
    };

    const fetchLocationAndPostalCode = async () => {
        if ("geolocation" in navigator) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                console.log('Latitude:', latitude, 'Longitude:', longitude);

                const locationData = await fetchPostalCode(latitude, longitude);
                if (locationData) {
                    const newOption = {
                        ...locationData,
                        value: locationData.id,
                        label: locationData.city + "(" + locationData.code + `)`,
                        coordinates: { lat: latitude, lon: longitude },
                    };
                    setSelectedOption(newOption);
                    setPostalCode(newOption);
                } else {
                    alert(t("No postal code found for this location, please enter manually."));
                }

                setShowPostalCodeError(false);
                setLoading(false);
            }, (error) => {
                console.error("Error getting location:", error);
                setLoading(false);
            });
        } else {
            alert("Geolocation is not supported by your browser.");
        }
    };

    const resetDropdown = () => {
        setInputValue("");
        setSelectedOption(null);
        setPostalCode(null);
    };

    return (
        <div className="col-12 col-lg d-flex flex-column">
            <div className="form-group mt-4 mt-sm-0">
                <label htmlFor="react-select-2-input">{ t("Where?") }</label>
                <div id="postal-code-container" className="input-container postal-code-container">
                    <AsyncPaginate
                        styles={ customStyles }
                        defaultOptions={ true }
                        value={ selectedOption }    // selected value
                        loadOptions={ loadOptions }
                        closeMenuOnSelect={ true }
                        isClearable={ true }
                        hideSelectedOptions={ false }
                        onChange={ handleSelectedValue }
                        placeholder={ t("Address or Postal Code") }
                        loadingMessage={ () => t("Loading...") }
                        noOptionsMessage={ () => t("No result found") }
                        menuIsScrollable
                        inputValue={ inputValue } // Set input value
                        onInputChange={ handleInputChange } // Handle input change
                        // menuIsOpen={true}
                        // onMenuScrollToBottom={()=>{}}
                        debounceTimeout={ 1000 }
                    />
                    <div className="input-icon">
                        <div className="icon-wrapper icon-wrapper--location">
                            <img src="/static/img/ic_lieu.svg" alt={ t("location") }/>
                        </div>
                    </div>
                    {/*<button id="resetButton" type="reset">*/ }
                    {/*    <img src="/static/img/ic_delete.svg" alt={ t("delete icon") }/>*/ }
                    {/*</button>*/ }
                    {/*<ResetButton query={ inputValue || selectedOption || "" } resetQuery={ resetDropdown }/>*/}
                    <button type="button" className="geolocation" disabled={ loading } onClick={ fetchLocationAndPostalCode }>
                        <img src="/static/img/ic_position.svg" alt={ t(" position") }/>
                        { loading
                            ?
                            <span className="d-none d-sm-inline-block">{ t("Loading...") }</span>
                            :
                                <span className="d-none d-sm-inline-block">{ t("Use my location") }</span>
                        }

                    </button>
                </div>
            </div>
            {
                showPostalCodeError
                    ?
                    <div className="postal-code-tooltip">{ t("Please fill in this field to continue the search") }</div>
                    : null
            }
            <a href={ currentLanguage === 'en' ? 'https://www.canadapost-postescanada.ca/cpc/en/tools/find-a-postal-code.page' : 'https://www.canadapost-postescanada.ca/scp/fr/outils/trouver-un-code-postal.page'} className="address-input-sub-label" target="_blank" rel="noopener noreferrer">{ t("Find a postal code") }</a>
        </div>
    )
        ;
};
PostalCode.prototype = {
    setPostalCode: PropTypes.func.isRequired,
    setShowPostalCodeError: PropTypes.func.isRequired,
    showPostalCodeError: PropTypes.bool.isRequired,
};

const Suggestions = ({ setShowSuggestion, showSuggestion, setQuery, suggestions, query }) => {
    const { t } = useTranslation();
    const [recentSearch, setRecentSearch] = useState(JSON.parse(localStorage.getItem("recentSearches")));
    const deleteRecentSearchItem = (index) => {
        const recentSearches = JSON.parse(localStorage.getItem("recentSearches"));
        recentSearches.splice(index, 1);
        localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
        setRecentSearch(recentSearches);
    };
    /**
     * Close suggestion modal
     *
     * @param {*} query
     */
    const closeSuggestionModal = (query) => {
        setShowSuggestion(false);
        setQuery(query);
    };

    /**
     * Handle the click for search query in suggestions modal
     *
     * @param {*} suggestion
     */
    const handleQueryClick = (suggestion) => {

        setQuery(suggestion);
        closeSuggestionModal(suggestion);

    };

    /**
     * Handle deleting recent search item
     *
     * @param {*} event
     * @param {*} index
     */
    const handleRecentSearchItemDelete = (event, index) => {
        event.stopPropagation();
        deleteRecentSearchItem(index);
    };

    /**
     * Function to render highlighted text
     * We will highlight the query in the suggestion, but not the user
     *
     * @param {*} suggestion
     * @param {*} query
     * @returns
     * @memberof Suggestions
     */
    const renderHighlightedText = (suggestion, query) => {
        const parts = suggestion.split(new RegExp(`(${query})`, "gi"));
        return (
            <span>
        { parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
                <span key={ index }>{ part }</span>
            ) : (
                <b key={ index }>{ part }</b>
            )
        ) }
      </span>
        );
    };

    return (
        <>
            { showSuggestion && (
                <div className="autocomplete-dropdown mb-0">
                    { recentSearch?.length > 0 && (
                        <div className="recent-searches">
                            <p className="recent-searches-title">{ t("Recent Searches") }</p>
                            <ul className="recent-search-list">
                                { recentSearch.map((query, index) => (
                                    <li
                                        className="recent-search-item"
                                        key={ index + query }
                                    >
                                        <button
                                            className="d-inline-flex align-items-center recent-search-item__btn"
                                            type="button"
                                            onClick={ () => handleQueryClick(query) }>
                                            <img
                                                aria-hidden="true"
                                                alt=""
                                                className="recent-search-item__clock-icon"
                                                src="/static/img/ic_duree.svg"
                                            />
                                            { query }
                                        </button>
                                        <button
                                            aria-label={ t("Delete recent search item") }
                                            className="recent-search-item__remove"
                                            type="button"
                                            onClick={ (event) =>
                                                handleRecentSearchItemDelete(event, index)
                                            }>
                                            <img
                                                className="recent-search-item__delete-icon"
                                                src="/static/img/clear-recent.svg"
                                            />
                                        </button>
                                    </li>
                                )) }
                            </ul>
                        </div>
                    ) }
                    {
                        suggestions?.length > 0 ? (
                            <div className="autocomplete-suggestions">
                                <p className="autocomplete-suggestions__title">Suggestions</p>
                                <ul className="autocomplete-suggestions__list">
                                    { suggestions.map((s) => (
                                        <li
                                            className="autocomplete-suggestions__list-item"
                                            key={ s }

                                        >
                                            {/*{renderHighlightedText(s, query)}*/ }
                                            <button role="button" onClick={ () => handleQueryClick(s) }>{ s }</button>
                                        </li>
                                    )) }
                                </ul>
                            </div>
                        ) : (
                            // <Loader/>
                            <div className="autocomplete-suggestions">
                                <p className="autocomplete-suggestions__list-item">{lang === "en" ? "Nothing is found" : "Rien n'est trouvé"}</p>
                            </div>
                        )
                    }
                </div>
            ) }
        </>
    );
};

Suggestions.propTypes = {
    showSuggestion: PropTypes.bool.isRequired,
    setShowSuggestion: PropTypes.func.isRequired,
    setQuery: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
    query: PropTypes.string.isRequired,
};


const AutoCompleteSearch = ({ query, setQuery, suggestions, id }) => {
    const { t } = useTranslation();
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    const handletyping = (query) => {
        setQuery(query);
        if (query.length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    /**
     * Start search when user press enter
     *
     * @param {*} event
     */
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setShowSuggestions(false);
            setQuery(event.target.value);
        }
    };

    /**
     * Suggestions modal ref
     */
    const specificDivRef = useRef(null);

    useEffect(() => {
        /**
         * Close suggestions modal on click outside
         *
         * @param {*} event
         */
        const handleCloseSuggestionModal = (event) => {
            if (
                specificDivRef.current &&
                !specificDivRef.current.contains(event.target)
            ) {
                setShowSuggestions(false);
            }
        };
        document.addEventListener("click", handleCloseSuggestionModal);
        return () => {
            document.removeEventListener("click", handleCloseSuggestionModal);
        };
    }, []);

    return (
        <div className="autocomplete-container" ref={ specificDivRef }>
            <input
                id={ id }
                className="form-control form-control-lg search-bar autocomplete-input"
                type="text"
                value={ query }
                placeholder={ t("Keyword (e.g. Alzheimer, organization...)") }
                onChange={ (e) => handletyping(e.target.value) }
                onFocus={ () => query.length > 0 && setShowSuggestions(true) }
                onKeyDown={ handleKeyDown }
            />
            {/*<div className="autocomplete-input-icon">*/ }
            {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="14px" height="20px">*/ }
            {/*        <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z" fill="#e6472a"/>*/ }
            {/*    </svg>*/ }
            {/*</div>*/ }
            {!isMobile && (
                <Suggestions
                    setQuery={ setQuery }
                    setShowSuggestion={ setShowSuggestions }
                    showSuggestion={ showSuggestions }
                    suggestions={ suggestions }
                    query={ query }
                />
            )}
        </div>
    );
};

AutoCompleteSearch.propTypes = {
    query: PropTypes.string.isRequired,
    setQuery: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
};

const OrganizationSearchTopForm = () => {
    const { t } = useTranslation();
    const [selectedPostalCode, setSelectedPostalCode] = useState(null);
    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const debouncedQuery = useDebounce(query, 500); // debounce time in milliseconds
    const [showPostalCodeError, setShowPostalCodeError] = useState(false);
    const inputId = "organization-top-search";
    const getSuggestions = async () => {
        try {
            const url = `/api/search/suggestions/?model=Resource&query=${ query }`;
            const resp = await axiosInstance.get(url);
            setSuggestions(resp.data.autocomplete);
        } catch (e) {
            setSuggestions([]);
        }
    };

    const submitHandler = (e) => {
        //     redirect to redirectUrl
        e.preventDefault();
        // save postal code and  services and sub services in local storage
        if (!selectedPostalCode) {
            setShowPostalCodeError(true);
        } else {
            localStorage.setItem("postalCode", JSON.stringify(selectedPostalCode));
            localStorage.setItem("query", query);
            localStorage.setItem("service", JSON.stringify({}));
            localStorage.setItem("subServices", JSON.stringify({}));
            // eslint-disable-next-line no-undef
            window.location.href = redirectUrl;
        }

    };

    useEffect(() => {
        if (debouncedQuery.length) {
            getSuggestions();
        }
    }, [debouncedQuery]);

    const resetQuery = () => {
        setQuery("");
    };

    return (<>
            <h2 className="repertoire-search-form-title">{ t("Search by postal code") }</h2>
            <form autoComplete="off">
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-12 col-lg d-flex flex-column mb-5 mb-lg-0">
                            <div className="form-group">
                                <label htmlFor={ inputId }>{ t("What are you looking for ?") }</label>
                                <div className="input-container keyword-search">
                                    <AutoCompleteSearch
                                        query={ query } setQuery={ setQuery }
                                        suggestions={ suggestions }
                                        id={ inputId }
                                    />
                                    <div className="input-icon">
                                        <div className="icon-wrapper icon-wrapper--search">
                                            <img className="input-search-icon" src="/static/img/search.svg" alt={ t(" location") }/>
                                        </div>
                                    </div>
                                    <ResetButton query={ query } resetQuery={ resetQuery }/>
                                </div>
                            </div>
                        </div>
                        <PostalCode
                            setPostalCode={ setSelectedPostalCode }
                            setShowPostalCodeError={ setShowPostalCodeError }
                            showPostalCodeError={ showPostalCodeError }
                        />
                        <div className="col-12 col-xl-auto d-flex flex-column align-items-center mt-5 mt-xl-0">
                            <button type="button" onClick={ submitHandler } className="btn-skin-1 m-0 address-search-button">
                                <span className="material-icons text-red">east</span>
                                <span>{ t("Search") }</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

const domNodeOrganizationSearch = document.querySelector("#topForm");

if (domNodeOrganizationSearch) {
    // const rootOrgSearch = ReactDOM.createRoot(domNodeOrganizationSearch);
    // rootOrgSearch.render(<OrganizationSearch/>,domNodeOrganizationSearch);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<OrganizationSearchTopForm/>, domNodeOrganizationSearch);
}