export const LANGUAGE_FR_CODE = "fr";
export const LANGUAGE_EN_CODE = "en";
export const ALL_TAB = "ALL_TAB";
export const NEWS_TAB = "NEWS_TAB";
export const DOCUMENTS_TAB = "DOCUMENTS_TAB";
export const GLOBAL_SEARCH_TAB = "GLOBAL_SEARCH_TAB";
export const RESOURCE_TAB = "RESOURCE_TAB";
export const TABS = [
  ALL_TAB,
  NEWS_TAB,
  DOCUMENTS_TAB,
  GLOBAL_SEARCH_TAB,
  RESOURCE_TAB,
];
export const NEWS_ELASTICSEARCH_DOCUMENT = "ActualityArticlePage";
export const DOCUMENTS_ELASTICSEARCH_DOCUMENT = "Publication";
export const GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT = "GeneralContent";
export const RESOURCE_ELASTICSEARCH_DOCUMENT = "Resource";
export const NEWS_ELASTICSEARCH_INDEX = "actuality-index";
export const DOCUMENT_ELASTICSEARCH_INDEX = "document-index";
export const GLOBAL_SEARCH_ELASTICSEARCH_INDEX = "general-content-index";
export const RESOURCE_ELASTICSEARCH_INDEX = "resources-index";

/**
 * Mapping each tab to it's elasticsearch document
 */
export const TAB_MAPPINGS = {
  ALL_TAB: {
    ActualityArticlePage: NEWS_ELASTICSEARCH_DOCUMENT,
    Publication: DOCUMENTS_ELASTICSEARCH_DOCUMENT,
    GeneralContent: GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
    Resource: RESOURCE_ELASTICSEARCH_DOCUMENT,
  },
  NEWS_TAB: {
    ActualityArticlePage: NEWS_ELASTICSEARCH_DOCUMENT,
  },
  DOCUMENTS_TAB: {
    Publication: DOCUMENTS_ELASTICSEARCH_DOCUMENT,
  },
  GLOBAL_SEARCH_TAB: {
    GeneralContent: GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
  },
  RESOURCE_TAB: {
    Resource: RESOURCE_ELASTICSEARCH_DOCUMENT,
  },
};

/**
 * Sort columns for each Tab
 */
export const DOCUMENT_SORTING_COLUMNS = {
  ActualityArticlePage: "title",
  Publication: "title",
  GeneralContent: "title",
  Resource: "name",
};

/**
 * mapping each tab to it's elasticsearch index
 */
export const TAB_INDEX_MAPPING = {
  [NEWS_TAB]: NEWS_ELASTICSEARCH_INDEX,
  [DOCUMENTS_TAB]: DOCUMENT_ELASTICSEARCH_INDEX,
  [GLOBAL_SEARCH_TAB]: GLOBAL_SEARCH_ELASTICSEARCH_INDEX,
  [RESOURCE_TAB]: RESOURCE_ELASTICSEARCH_INDEX,
};

// ElasticSearch API operators
export const IS = "is";
export const IS_NOT = "is_not";
export const CONTAIN = "contain";
export const SMALLER = "smaller";
export const GREATER = "greater";
export const ISTARTSWITH = "istartswith";

// Available search operators
export const AND = "and";
export const OR = "or";

// Sorting
export const ORDER_BY_RELEVANCE = "ORDER_BY_RELEVANCE";
export const ORDER_ALPHABETICALLY = "ORDER_ALPHABETICALLY";

// Pagination page size
export const PAGE_SIZE = 10; // The page size need to be modified in the backend as well

// Result views Types
export const LIST_VIEW = "LIST_VIEW";
export const GRID_VIEW = "GRID_VIEW";

// Alert Type
export const SUCCESS = "success";
export const DANGER = "danger";
export const WARNING = "warning";


// Filters
export const AGE_GROUPE_FILTER = "age_groups";
export const SERVICES_FILTER = "services.core_service";
export const SUB_SERVICES_FILTER = "services.sub_service";
export const ILLNESSES_FILTER = "illnesses";

/**
 * Aggregate filters
 */
export const AGGREGATE_FILTERS = {
  [`${AGE_GROUPE_FILTER}.id`]: "term",
  [`services`]: "term",
  ['sub_services']: "term",
  [`${ILLNESSES_FILTER}.id`]: "term",
};

export const RESOURCE_FILTERS = [
  AGE_GROUPE_FILTER,
  SERVICES_FILTER,
  ILLNESSES_FILTER,
];

export const RECENT_SEARCHES_STORAGE = "recentSearches";
export const MAX_RECENT_SEARCHES_LENGTH = 3;

// Default resource filters
export const IS_PUBLISHED_FILTER = {
  condition: "and",
  field: "publish",
  operator: "is",
  value: true,
};
