import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import axiosInstance from "../components/OrganisationSearch/services/api";
import { AsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";
import "../i18n";
import { fetchPostalCode, getCoordinatesFromPostalCode, getLKey } from "../components/OrganisationSearch/utils/helpers";
import { customStyles } from "../components/OrganisationSearch/components/PostalCode/styles";
import ResetButton from "../components/OrganisationSearch/components/ResetButton";
import AutoCompleteSearch from "../components/OrganisationSearch/components/common/autocompleteSearch";
import { useDebounce } from "./OrganisationSearchTopForm";

const IMAGE = {
    "respite": "ic_repit.svg",
    "information": "ic_informations.svg",
    "trainings": "ic_activite_formation.svg",
    "home_assists": "ic_aide_domicile.svg",
    "administratives": "ic_juridique.svg",
    "transportations": "ic_transport.svg",
    "accommodations": "ic_hebergement.svg",
    "support_groups": "ic_entraide.svg",

};

const fetchServices = async () => {
    const url = `/api/services/`;
    const response = await axiosInstance.get(url);
    return response.data;
};
const fetchSubServices = async (service) => {
    const url = `/api/search/related-field-model/?field=${ service }&document=Resource&page_size=100&page=1`;
    const response = await axiosInstance.get(url);
    return response.data;
};

// eslint-disable-next-line react/prop-types
const PostalCode = ({ setPostalCode, setShowPostalCodeError, showPostalCodeError }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const currentLanguage = window.languageCode;

    const loadOptions = async (inputValue = "", prevOptions) => {
        try {
            const response = await axiosInstance.get(
                "/api/postal-code/?q=" + inputValue
            );
            const data = response.data;

            const newOptions = data.results.map((item) => ({
                ...item,
                value: item.id,
                label: item.city + "(" + item.code + `)`,
            }));
            return {
                options: newOptions,
            };
        } catch (error) {
            console.log(prevOptions);
            console.error("An error occurred while fetching data:", error);
            return [];
        }
    };

    const handleSelectedValue = async (selected) => {
        const coordinates = await getCoordinatesFromPostalCode(selected.code);
        if (coordinates) {
            const newSelected = {
                ...selected,
                coordinates: coordinates,
            };
            setSelectedOption(newSelected);
            setPostalCode(newSelected);
            setShowPostalCodeError(false);
            setInputValue(selected);
        } else {
            alert(t("Can not select this postal code at the moment, please another postalcode nearest to you."));
        }
    };

    const handleInputChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            setInputValue(newValue);
        }
    };

    const fetchLocationAndPostalCode = async () => {
        if ("geolocation" in navigator) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                console.log('Latitude:', latitude, 'Longitude:', longitude);

                const locationData = await fetchPostalCode(latitude, longitude);
                if (locationData) {
                    const newOption = {
                        ...locationData,
                        value: locationData.id,
                        label: locationData.city + "(" + locationData.code + `)`,
                        coordinates: { lat: latitude, lon: longitude },
                    };
                    setSelectedOption(newOption);
                    setPostalCode(newOption);
                } else {
                    alert(t("No postal code found for this location, please enter manually."));
                }

                setShowPostalCodeError(false);
                setLoading(false);
            }, (error) => {
                console.error("Error getting location:", error);
                setLoading(false);
            });
        } else {
            alert("Geolocation is not supported by your browser.");
        }
    };

    const newCustomStyles = {
        ...customStyles,
        control: (provided, state) => ({
            ...provided,
            minHeight: '40px',
            paddingLeft: '74px',
            background: '#ffffff',
            border: 'initial',
            boxShadow: 'initial',
            '&:hover': {
                border: 'initial',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#004071 !important',
            fontSize: '16px',
            margin: '0',
            '@media (max-width: 575px)': {
                fontSize: '13px',
                lineHeight: '20px'
            }
        }),
    };

    const resetDropdown = () => {
        setInputValue("");
        setSelectedOption(null);
        setPostalCode(null);
    };

    return (
        <>
            <div className="form-group">
                {/*<label htmlFor="{{ form.postal_code.id_for_label }}">{ t("Where?") }</label>*/ }
                <div className="input-container postal-code-container p-0 bg-white">
                    <AsyncPaginate
                        styles={ newCustomStyles }
                        defaultOptions={ true }
                        value={ selectedOption }
                        loadOptions={ loadOptions }
                        closeMenuOnSelect={ true }
                        isClearable={ true }
                        hideSelectedOptions={ false }
                        onChange={ handleSelectedValue }
                        placeholder={ t("Postal code or city") }
                        loadingMessage={ () => t("Loading...") }
                        noOptionsMessage={ () => t("No result found") }
                        menuIsScrollable
                        inputValue={ inputValue }
                        onInputChange={ handleInputChange }
                        debounceTimeout={ 1000 }
                    />
                    <div className="input-icon">
                        <div className="icon-wrapper icon-wrapper--location">
                            <img src="/static/img/ic_lieu.svg" alt={ t("location") }/>
                        </div>
                    </div>
                    {/*<ResetButton query={ inputValue || selectedOption || "" } resetQuery={ resetDropdown }/>*/}
                    <button type="button" className="geolocation" disabled={ loading } onClick={ fetchLocationAndPostalCode }>
                        <img src="/static/img/ic_position.svg" alt={ t(" position") }/>
                        { loading
                            ?
                            <span className="d-none d-sm-inline-block">{ t("Loading...") }</span>
                            :
                            <span className="d-none d-sm-inline-block">{ t("Use my location") }</span>
                        }

                    </button>
                </div>
                {
                    showPostalCodeError
                        ?
                        <div className="postal-code-tooltip">{ t("Please fill in this field to continue the search") }</div>
                        : null
                }
            </div>

            {/*<a href={ currentLanguage === 'en' ? 'https://www.canadapost-postescanada.ca/cpc/en/tools/find-a-postal-code.page' : 'https://www.canadapost-postescanada.ca/scp/fr/outils/trouver-un-code-postal.page' } className="address-input-sub-label" target="_blank" rel="noopener noreferrer">{ t("Find a postal code") }</a>*/ }
        </>
    );
};
PostalCode.prototype = {
    setPostalCode: PropTypes.func.isRequired,
    setShowPostalCodeError: PropTypes.func.isRequired,
    showPostalCodeError: PropTypes.bool.isRequired,
};


const OrganizationSearchHome = () => {
    const { t } = useTranslation();
    const [service, setService] = useState(null);
    const [services, setServices] = useState(null);
    const [subServices, setSubServices] = useState([]);
    const [selectedSubServices, setSelectedSubServices] = useState([]);
    const [selectedPostalCode, setPostalCode] = useState(null);
    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const debouncedQuery = useDebounce(query, 500);
    const [showPostalCodeError, setShowPostalCodeError] = useState(false);
    const inputId = "organization-top-search";

    const getServices = async () => {
        const response = await fetchServices();
        setServices(response);
    };

    const getSuggestions = async () => {
        try {
            const url = `/api/search/suggestions/?model=Resource&query=${ query }`;
            const resp = await axiosInstance.get(url);
            setSuggestions(resp.data.autocomplete);
        } catch (e) {
            setSuggestions([]);
        }
    };

    const handleServiceSelection = (service) => {
        const selectedService = services.filter(s => s.name === service)[0];
        setService(selectedService);
        if (selectedService.sub_services.length) {
            setSubServices(selectedService.sub_services);
            setSelectedSubServices(selectedService.sub_services.reduce((a, c) => {
                a[c.name] = true;
                return a;
            }, {}));
        } else {
            fetchSubServices(service).then(response => {
                let subServices = response.results;
                subServices = subServices.map(s => ({ ...s, name: s.value }));
                setSubServices(subServices);
                setSelectedSubServices(subServices.reduce((a, c) => {
                    a[c.name] = true;
                    return a;
                }, {}));
            });
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (!selectedPostalCode) {
            setShowPostalCodeError(true);
        } else {
            localStorage.setItem("postalCode", JSON.stringify(selectedPostalCode));
            localStorage.setItem("query", query);
            localStorage.setItem("service", JSON.stringify(service));
            localStorage.setItem("subServices", JSON.stringify(selectedSubServices));
            // eslint-disable-next-line no-undef
            window.location.href = redirectUrl;
        }
    };

    useEffect(() => {
        getServices();
    }, []);

    useEffect(() => {
        if (debouncedQuery.length) {
            getSuggestions();
        }
    }, [debouncedQuery]);

    const resetQuery = () => {
        setQuery("");
    };
    return (
        <>
            <div className="home-section-one-wrapper" id="repo-form">
                <div className="top-txt">
                    <p className="title-small">{ t("Resource Directory") }</p>
                    <h2 className="d1-alt-md top-txt-title">{ t("Help is available near your location") }</h2>
                    <div className="input-wrapper">
                        <div className="d-flex flex-column w-100">
                            <PostalCode setPostalCode={ setPostalCode }
                                        showPostalCodeError={ showPostalCodeError }
                                        setShowPostalCodeError={ setShowPostalCodeError }
                            />
                            <div className="form-group mt-4">
                                <div className="input-container keyword-search">
                                    <AutoCompleteSearch
                                        query={ query }
                                        setQuery={ setQuery }
                                        suggestions={ suggestions }
                                        id={ inputId }
                                    />
                                    <ResetButton query={ query } resetQuery={ resetQuery }/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-section-two-wrapper">
                <div className="top-txt">
                    <p className="top-txt-title">{ t("Services") }</p>
                </div>
                <div className="services-buttons-wrapper">

                    <div className="service-btn-container">
                        <input className="service-btn-input" type="radio" id="respite_location" name="service" checked={ service?.name === "respite_location" }
                               onClick={ () => handleServiceSelection("respite_location") } readOnly/>
                        <label className="service-btn" htmlFor="respite_location">
                            <img className="service-btn-img" src="/static/img/ic_repit.svg" alt={ t(" respite icon") }/>
                            <span className="service-btn-text">
                                       { t("Respite") }
                                </span>
                        </label>
                    </div>

                    <div className="service-btn-container">
                        <input className="service-btn-input" type="radio" id="informations" name="service" checked={ service?.name === "information" }
                               onClick={ () => handleServiceSelection("information") } readOnly/>
                        <label className="service-btn" htmlFor="informations">
                            <img className="service-btn-img" src="/static/img/ic_informations.svg" alt={ t("information and documentation icon") }/>
                            <span className="service-btn-text">
                                       { t("Information And Documentation") }
                                </span>
                        </label>
                    </div>
                    <div className="service-btn-container">
                        <input className="service-btn-input" type="radio" id="trainings" name="service" checked={ service?.name === "trainings" }
                               onClick={ () => handleServiceSelection("trainings") } readOnly/>
                        <label className="service-btn" htmlFor="trainings">
                            <img className="service-btn-img" src="/static/img/ic_activite_formation.svg" alt={ t(" training activities icon") }/>
                            <span className="service-btn-text">
                                       { t("Training Activities") }
                                </span>
                        </label>
                    </div>
                    <div className="service-btn-container">
                        <input className="service-btn-input" type="radio" id="support_groups" name="service" checked={ service?.name === "support_groups" }
                               onClick={ () => handleServiceSelection("support_groups") } readOnly/>
                        <label className="service-btn" htmlFor="support_groups">
                            <img className="service-btn-img" src="/static/img/ic_entraide.svg" alt={ t(" mutual aid and support icon") }/>
                            <span className="service-btn-text">
                                       { t("Mutual Aid And Support") }
                                </span>
                        </label>
                    </div>
                    <div className="service-btn-container">
                        <input className="service-btn-input" type="radio" id="administratives" name="service" checked={ service?.name === "administratives" }
                               onClick={ () => handleServiceSelection("administratives") } readOnly/>
                        <label className="service-btn" htmlFor="administratives">
                            <img className="service-btn-img" src="/static/img/ic_juridique.svg" alt={ t(" administrative, legal & fiscal icon") }/>
                            <span className="service-btn-text">
                                       { t("Administrative, Legal & Fiscal") }
                                </span>
                        </label>
                    </div>
                    <div className="service-btn-container">
                        <input className="service-btn-input" type="radio" id="home_assists" name="service" checked={ service?.name === "home_assists" }
                               onClick={ () => handleServiceSelection("home_assists") } readOnly/>
                        <label className="service-btn" htmlFor="home_assists">
                            <img className="service-btn-img" src="/static/img/ic_aide_domicile.svg" alt={ t(" home assistance icon") }/>
                            <span className="service-btn-text">
                                       { t("Home Assistance") }
                                </span>
                        </label>
                    </div>
                    <div className="service-btn-container">
                        <input className="service-btn-input" type="radio" id="transportations" name="service" checked={ service?.name === "transportations" }
                               onClick={ () => handleServiceSelection("transportations") } readOnly/>
                        <label className="service-btn" htmlFor="transportations">
                            <img className="service-btn-img" src="/static/img/ic_transport.svg" alt={ t(" transportation or accompaniment icon") }/>
                            <span className="service-btn-text">
                                       { t("Transportation / Accompaniment") }
                                </span>
                        </label>
                    </div>
                    <div className="service-btn-container">
                        <input className="service-btn-input" type="radio" id="accommodations" name="service" checked={ service?.name === "accommodations" }
                               onClick={ () => handleServiceSelection("accommodations") } readOnly/>
                        <label className="service-btn" htmlFor="accommodations">
                            <img className="service-btn-img" src="/static/img/ic_hebergement.svg" alt={ t(" lodging or accommodation icon") }/>
                            <span className="service-btn-text">
                                       { t("Lodging / Accommodation") }
                                </span>
                        </label>
                    </div>


                </div>
                <button type="button" value="Submit" onClick={ submitHandler } className="btn-skin-1 home-section-two-btn" id="submit-search">
                    <span className="material-icons">east</span>
                    { t("Search") }
                </button>
            </div>
        </>);
};

const domNodeOrganizationSearch = document.querySelector("#organisationSearchHome");
console.log("Logging domNodeOrganizationSearch", domNodeOrganizationSearch);
if (domNodeOrganizationSearch) {
    // const rootOrgSearch = ReactDOM.createRoot(domNodeOrganizationSearch);
    // rootOrgSearch.render(<OrganizationSearch/>,domNodeOrganizationSearch);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<OrganizationSearchHome/>, domNodeOrganizationSearch);
}