import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
    setAutoCompleteQuery,
    toggleSuggestionsModal,
} from "../../../store/actions/Autocomplete";
import { setSearchQuery } from "../../../store/actions/Filters";
import { RESOURCE_ELASTICSEARCH_DOCUMENT } from "../../../constants/appConstants";
import Suggestions from "./suggestions";
import {
    setElasticSearchDocumentDependingOnURL,
    setSearchRequestComesFromPage
} from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

const AutoCompleteSearchHeader = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchQuery = useSelector((state) => state.autoComplete.query);
    const currentLanguage = window.languageCode;
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showRecent, setShowRecent] = useState(false);
    const [showQuickLinks, setShowQuickLinks] = useState(false);
    const specificDivRef = useRef(null);
    const activeElasticSearchDocument = setElasticSearchDocumentDependingOnURL();
    const searchRequestComesFromPage = setSearchRequestComesFromPage();

    const quickLinks = {
        en: [
            {
                text: 'Preparing for ambiguous loss and grief',
                url: 'https://www.lappui.org/en/practical-advices/understand-the-situation-of-the-person-i-am-caring-for/alzheimer-s-disease-and-other-neurocognitive-disorders/preparing-for-ambiguous-loss-and-grief-involving-a-close-family-member-with-alzheimers-disease/'
            },
            {
                text: 'Caregiver tax credit',
                url: 'https://www.lappui.org/en/practical-advices/navigating-the-administrative-process/protection-mandate-and-legal-issues/caregiver-tax-credit-at-a-glance/'
            },
            {
                text: 'Understand the situation of the person I am caring for',
                url: 'https://www.lappui.org/en/practical-advices/understand-the-situation-of-the-person-i-am-caring-for/'
            },
            {
                text: 'Staying at home, moving to a residence',
                url: 'https://www.lappui.org/en/practical-advices/navigating-the-administrative-process/staying-at-home-moving-to-a-residence/'
            },
            {
                text: 'Knowing the rights and status of the caregiver',
                url: 'https://www.lappui.org/en/practical-advices/being-a-caregiver/knowing-the-rights-and-status-of-the-caregiver/'
            },
        ],
        fr: [
            {
                text: 'Faire le deuil blanc de son proche',
                url: 'https://www.lappui.org/fr/je-suis-aidant/comprendre-la-situation-de-mon-proche/alzheimer-et-maladies-neurodegeneratives-apparentees/le-deuil-blanc/'
            },
            {
                text: "Le crédit d'impôt pour personne proche aidante",
                url: 'https://www.lappui.org/fr/je-suis-aidant/demarches-administratives/formalites-administratives-et-fiscalite/le-credit-d-impot-pour-personne-proche-aidante/'
            },
            {
                text: 'Comprendre la situation de mon proche',
                url: 'https://www.lappui.org/fr/je-suis-aidant/comprendre-la-situation-de-mon-proche/'
            },
            {
                text: 'Maintien à domicile ou hébergement',
                url: 'https://www.lappui.org/fr/je-suis-aidant/demarches-administratives/maintien-a-domicile-ou-hebergement/'
            },
            {
                text: 'Connaître les droits et les statuts du proche aidant',
                url: 'https://www.lappui.org/fr/je-suis-aidant/etre-proche-aidant/connaitre-les-droits-et-les-statuts-du-proche-aidant/'
            },
        ],
    };

    const handleTyping = (query) => {
        dispatch(setAutoCompleteQuery(query, activeElasticSearchDocument));
        if (query.length > 0) {
            dispatch(toggleSuggestionsModal(true));
            setShowSuggestions(true);
            setShowRecent(false);
            setShowQuickLinks(false);
        } else {
            // dispatch(toggleSuggestionsModal(false));
            setShowSuggestions(true);
            setShowRecent(true);
            setShowQuickLinks(true);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const langCode = currentLanguage === 'en' ? 'en' : 'fr';
            const slug = currentLanguage === 'en' ? 'global-search' : 'recherche-globale';
            const query = event.target.value;
            const activeElasticSearch = setElasticSearchDocumentDependingOnURL();
            const searchRequestComesFromPage = setSearchRequestComesFromPage();
            const url = `/${ langCode }/${ slug }?f=${ searchRequestComesFromPage }&tab=${ activeElasticSearch }&query=${ query }`;
            window.location.href = url;

            dispatch(toggleSuggestionsModal(false));
            dispatch(setSearchQuery(query));
            setShowSuggestions(false);
            setShowRecent(false);
            setShowQuickLinks(false);
        }
    };

    const handleFocus = () => {
        dispatch(toggleSuggestionsModal(true));
        setShowSuggestions(true);
        setShowRecent(true);
        setShowQuickLinks(true);
    };

    const closeSearch = (event) => {
        const mainNav = document.querySelector('#top-nav-wrapper');

        if (mainNav) {
            mainNav.classList.remove('search-open');
            //addContainerClickListener();
        }
        event.stopPropagation();
    };

    const handleCloseButtonClick = (event) => {
        dispatch(toggleSuggestionsModal(false));
        setShowSuggestions(false);
        setShowRecent(false);
        setShowQuickLinks(false);
        dispatch(setAutoCompleteQuery('', RESOURCE_ELASTICSEARCH_DOCUMENT));
        event.stopPropagation();
        closeSearch(event);
    };

    useEffect(() => {
        const handleCloseSuggestionModal = (event) => {
            if (
                specificDivRef.current &&
                !specificDivRef.current.contains(event.target) &&
                !event.target.classList.contains('close-global-search-btn')
            ) {
                dispatch(toggleSuggestionsModal(false));
                setShowSuggestions(false);
                setShowRecent(false);
                setShowQuickLinks(false);
                dispatch(setAutoCompleteQuery('', RESOURCE_ELASTICSEARCH_DOCUMENT));
            }
        };
        document.addEventListener("click", handleCloseSuggestionModal);
        return () => {
            document.removeEventListener("click", handleCloseSuggestionModal);
        };
    }, []);

    const renderQuickLinks = () => (
        <div className="quick-links">
            {/*<p className="quick-links__title">{currentLanguage === 'en' ? 'Quick links' : 'Liens rapides'}</p>*/ }
            <ul className="quick-links__list">
                { quickLinks[currentLanguage].map((link, index) => (
                    <li key={ index } className="quick-links__item">
                        <a href={ link.url } className="quick-links__link" target="_blank" rel="noopener noreferrer">
                            { link.text }
                        </a>
                    </li>
                )) }
            </ul>
        </div>
    );

    return (
        <div
            className={ `autocomplete-container${ showSuggestions || showQuickLinks ? ' open-list' : '' }` }
            ref={ specificDivRef }
        >
            <div className="autocomplete-input-container">
                <button type="button" className="open-global-search-btn">
                    <i className="fas fa-search"></i>
                </button>
                <input
                    className="form-control form-control-lg search-bar autocomplete-input"
                    type="text"
                    value={ searchQuery }
                    placeholder={ currentLanguage === 'en' ? 'Search...' : 'Recherchez...' }
                    onChange={ (e) => handleTyping(e.target.value) }
                    onFocus={ handleFocus }
                    onKeyDown={ handleKeyDown }
                />
                <button
                    role="button"
                    className="close-global-search-btn"
                    onClick={ handleCloseButtonClick }
                >
                    <i className="fas fa-times"></i>
                </button>
            </div>
            { showQuickLinks && renderQuickLinks() }
            { showSuggestions && <Suggestions showRecent={ showQuickLinks }/> }
        </div>
    );
};

AutoCompleteSearchHeader.propTypes = {
    value: PropTypes.string,
};

export default AutoCompleteSearchHeader;