import {
  SET_NEWS_FILTERS,
  SET_DOCUMENT_FILTERS,
  SET_GLOBAL_SEARCH_FILTERS,
  SET_RESOURCE_FILTERS,
  BUILD_ELASTIC_SEARCH_QUERY,
  SET_ACTIVE_TAB,
  SET_SEARCH_QUERY,
  SET_PAGE,
  SET_SORT_BY,
} from "../../constants/ActionTypes";
import {
  GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
  FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT,
} from "../../constants/appConstants";
import { debounce } from "../../utils/helpers";
import { startSearch } from "./Search";
import { saveRecentSearches } from "./Autocomplete";

/**
 * Generic Fields fetch
 *
 * @param {*} actionType
 * @param {*} fieldDocument
 * @return {*}
 */
const setFilterValue = (
  actionType,
  document,
  field,
  data,
  columnName,
  operator
) => {
  return (dispatch) => {
    dispatch({
      type: actionType,
      document: document,
      payload: data,
      field: field,
      columnName: columnName,
      operator: operator,
    });
    dispatch(setPage(1));
    dispatch(buildElasticSearchQuery());
    dispatch(startSearch());
  };
};


/**
 * set Global search filters
 *
 * @returns
 */
export const setGlobalSearchFilter = (field, data, columnName, operator) => {
  return setFilterValue(
    SET_GLOBAL_SEARCH_FILTERS,
    GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
    field,
    data,
    columnName,
    operator
  );
};


/**
 * Build elasticsearch query
 *
 * @returns
 */
export const buildElasticSearchQuery = () => {
  return (dispatch, getState) => {
    dispatch({
      type: BUILD_ELASTIC_SEARCH_QUERY,
      currentlanguage: getState().common.currentLanguage,
    });
  };
};

/**
 * Debounce for search query
 */
const debouncedStartSearch = debounce((dispatch) => {
  dispatch(setPage(1));
  dispatch(startSearch());
}, 600);

export const setSearchQuery = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_QUERY,
      payload: value,
    });
        dispatch(saveRecentSearches(value));
    debouncedStartSearch(dispatch);
  };
};

export const setSorting = (sorting) => {
  return (dispatch) => {
    dispatch({
      type: SET_SORT_BY,
      payload: sorting,
    });
    dispatch(startSearch());
  };
};

/**
 * Set active filters tab
 *
 * @param {*} tab
 * @returns
 */
export const setActiveTab = (tab) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_TAB,
      payload: tab,
    });
    dispatch(startSearch());
  };
};

export const setPage = (page) => {
  return (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: page,
    });
    dispatch(startSearch());
  };
};
