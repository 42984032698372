import {
  SET_NEWS_FILTERS,
  SET_DOCUMENT_FILTERS,
  SET_GLOBAL_SEARCH_FILTERS,
  BUILD_ELASTIC_SEARCH_QUERY,
  SET_ACTIVE_TAB,
  SET_SEARCH_QUERY,
  SET_PAGE,
  SET_SORT_BY,
  SET_RESOURCE_FILTERS,
} from "../../constants/ActionTypes";
import {
  ALL_TAB, FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT,
  GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
  ORDER_BY_RELEVANCE, PRACTICAL_ADVICE_ELASTICSEARCH_DOCUMENT,
} from "../../constants/appConstants";
import { baseFilters, transformStateToPayload } from "../../utils/helpers";

const INIT_STATE = {
  filters: {
    [GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT]: {},
    [FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT]: {},
    [PRACTICAL_ADVICE_ELASTICSEARCH_DOCUMENT]: {},
  },
  payload: {
    query: "",
    page: 1,
    filters: [...baseFilters],
  },
  searchQuery: "",
  page: 1,
  activeTab: ALL_TAB,
  sorting: ORDER_BY_RELEVANCE,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_GLOBAL_SEARCH_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT]: {
            ...state.filters[GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT],
            [action.field]: {
              field: action.columnName,
              value: action.payload,
              operator: action.operator,
            },
          },
        },
      };
    }
    case SET_SORT_BY: {
      return {
        ...state,
        sorting: action.payload,
      };
    }

    case BUILD_ELASTIC_SEARCH_QUERY: {
      const payload = transformStateToPayload(
        state.filters,
        state.activeTab,
        state.searchQuery,
        state.page,
        state.sorting,
        action.currentlanguage
      );
      return {
        ...state,
        payload: payload,
      };
    }
    case SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload,
      };
    }
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
        page: 1,
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }

    default:
      return state;
  }
};
