import axios from "axios";
import { getCookie } from "../utils/helpers";

/**
 * Get our Django csrf token
 */
const csrftoken = getCookie("csrftoken");

/**
 * Create Global axios instance so we can use it all across the checkout process
 */
const axiosInstance = axios.create({
  baseURL: "",
  headers: {
    Accepted: "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken,
    'Accept-Language': '*',
  },
});

/**
 * add extra config to our axios by intercepting requests
 */
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  /* eslint-disable no-undef */
  (error) => Promise.reject(error)
);

export default axiosInstance;
