import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../components/GlobalSearch/components/common/loader";

const Suggestions = ({ setShowSuggestion, showSuggestion, setQuery, suggestions, query }) => {
    const { t } = useTranslation();
    const lang = document.documentElement.lang || "en";
    const [recentSearch, setRecentSearch] = useState(JSON.parse(localStorage.getItem("recentSearches")) || []);

    const deleteRecentSearchItem = (index) => {
        const recentSearches = JSON.parse(localStorage.getItem("recentSearches")) || [];
        recentSearches.splice(index, 1);
        localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
        setRecentSearch(recentSearches);
    };

    const closeSuggestionModal = (query) => {
        setShowSuggestion(false);
        setQuery(query);
    };

    const handleQueryClick = (suggestion) => {
        setQuery(suggestion);
        closeSuggestionModal(suggestion);
    };

    const handleRecentSearchItemDelete = (event, index) => {
        event.stopPropagation();
        deleteRecentSearchItem(index);
    };

    const renderHighlightedText = (suggestion, query) => {
        const parts = suggestion.split(new RegExp(`(${query})`, "gi"));
        return (
            <span>
                { parts.map((part, index) =>
                    part.toLowerCase() === query.toLowerCase() ? (
                        <span key={ index }>{ part }</span>
                    ) : (
                        <b key={ index }>{ part }</b>
                    )
                ) }
            </span>
        );
    };

    return (
        <>
            { showSuggestion && (
                <div className="autocomplete-dropdown mb-0">
                    { recentSearch.length > 0 && (
                        <div className="recent-searches">
                            <p className="recent-searches-title">{ t("Recent Searches") }</p>
                            <ul className="recent-search-list">
                                { recentSearch.map((query, index) => (
                                    <li className="recent-search-item" key={ index + query }>
                                        <button className="d-inline-flex align-items-center recent-search-item__btn" type="button" onClick={ () => handleQueryClick(query) }>
                                            <img aria-hidden="true" alt="" className="recent-search-item__clock-icon" src="/static/img/ic_duree.svg"/>
                                            { query }
                                        </button>
                                        <button aria-label={ t("Delete recent search item") } className="recent-search-item__remove" type="button" onClick={ (event) => handleRecentSearchItemDelete(event, index) }>
                                            <img className="recent-search-item__delete-icon" src="/static/img/clear-recent.svg"/>
                                        </button>
                                    </li>
                                )) }
                            </ul>
                        </div>
                    ) }
                    { suggestions.length > 0 ? (
                        <div className="autocomplete-suggestions">
                            <p className="autocomplete-suggestions__title">{ t("Suggestions") }</p>
                            <ul className="autocomplete-suggestions__list">
                                { suggestions.map((s) => (
                                    <li className="autocomplete-suggestions__list-item" key={ s }>
                                        <button role="button" onClick={ () => handleQueryClick(s) }>{ s }</button>
                                    </li>
                                )) }
                            </ul>
                        </div>
                    ) : (
                        <div className="autocomplete-suggestions">
                            <p className="autocomplete-suggestions__list-item">{ lang === "en" ? "Nothing is found" : "Rien n'est trouvé" }</p>
                        </div>
                    ) }
                </div>
            ) }
        </>
    );
};

Suggestions.propTypes = {
    showSuggestion: PropTypes.bool.isRequired,
    setShowSuggestion: PropTypes.func.isRequired,
    setQuery: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
    query: PropTypes.string.isRequired,
};

export default Suggestions;
