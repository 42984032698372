import { SEARCH_SUCCESS, SET_VIEW_TYPE } from "../../constants/ActionTypes";
import { LIST_VIEW } from "../../constants/appConstants";

const INIT_STATE = {
  results: [],
  viewType: LIST_VIEW,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEARCH_SUCCESS: {
      return {
        ...state,
        results: action.payload,
      };
    }
    case SET_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.payload,
      };
    }

    default:
      return state;
  }
};
