import { SEARCH_SUCCESS, SET_VIEW_TYPE } from "../../constants/ActionTypes";
import axios from "../../services/api";
import { FETCH_START, FETCH_SUCCESS } from "../../constants/ActionTypes";
import { getCookie } from "../../utils/helpers";
import { buildElasticSearchQuery } from "./Filters";
import { fetchError } from "./Common";

/**
 * Get our Django csrf token
 */
const csrftoken = getCookie("csrftoken");

/**
 * Start search
 *
 * @param {*} actionType
 * @param {*} fieldDocument
 * @return {*}
 */
export const startSearch = () => {
  return (dispatch, getState) => {
    dispatch(buildElasticSearchQuery());
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
    const language = window.languageCode || "en";
    const url = `/${language}/api/search/`;
    console.log("url", url);
    axios
      .post(url, getState().filters.payload)
      .then(({ data }) => {
        function getMatchedProperty(description) {
          const match = description.match(/weight\((\w+):/);
          return match ? match[1] : null;
        }
        function findAllMatchingDetails(explain) {
          const matches = [];
          if (!explain.details) return matches;
          for (const detail of explain.details) {
            if (detail.description.includes("weight")) {
              matches.push(detail);
            }
            matches.push(...findAllMatchingDetails(detail));
          }
          return matches;
        }


        const documents = data.results;
documents.forEach((doc) => {
  const result = doc.result;
  const title = language === "en" ? result.title_en : result.title_fr;
  const score = result._explain.value;

  console.log(`Title (${language}): ${title}`);
  console.log(`Score: ${score}`);

  // Find all matching properties in the _explain object
  const matchingDetails = findAllMatchingDetails(result._explain);

  if (matchingDetails.length > 0) {
    console.log("Matched properties (sorted by score):");

    // Sort by decreasing score
    matchingDetails.sort((a, b) => b.value - a.value);

    matchingDetails.forEach((detail, index) => {
      const matchedProperty = getMatchedProperty(detail.description);
      const propertyValue = matchedProperty ? result[matchedProperty] : "Unknown";

      console.log(`${index + 1}. ${detail.description}`);
      console.log(`   Why selected: ${detail.value}`);
      console.log(`   Matched property: ${matchedProperty || "Unknown"}`);
      console.log(`   Property value: ${propertyValue}`);
    });
  } else {
    console.log("No matching properties found in explanation.");
  }
  console.log("-".repeat(50));
});
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SEARCH_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch(
          fetchError({ message: error.message, status: error.response.status })
        );
      });
  };
};

export const setResultViewtype = (viewType) => {
  return (dispatch) => {
    dispatch({
      type: SET_VIEW_TYPE,
      payload: viewType,
    });
  };
};
